
.ltr0_1 {
  animation: ltr0_1_kf 0.2s ease-in 0s 1 both;
}
@keyframes ltr0_1_kf {
  0% {
    left: 0px; 
  }
  90% {
    left: 42px;
  }
  93% {
    left: 39.1px;
  }
  100% {
    left: 42px;
  }
}


.rtl1_0 {
  animation: rtl1_0_kf 0.2s ease-in 0s 1 both;
}
@keyframes rtl1_0_kf {
  0% {
    left: 42px; 
  }
  90% {
    left: 0px;
  }
  93% {
    left: 0.0px;
  }
  100% {
    left: 0px;
  }
}


.btt1_0 {
  animation: btt1_0_kf 0.2s ease-in 0s 1 both;
}
@keyframes btt1_0_kf {
  0% {
    top: 42px; 
  }
  90% {
    top: 0px;
  }
  93% {
    top: 0.0px;
  }
  100% {
    top: 0px;
  }
}


.ltr0_2 {
  animation: ltr0_2_kf 0.3s ease-in 0s 1 both;
}
@keyframes ltr0_2_kf {
  0% {
    left: 0px; 
  }
  90% {
    left: 84px;
  }
  93% {
    left: 78.1px;
  }
  100% {
    left: 84px;
  }
}


.rtl2_0 {
  animation: rtl2_0_kf 0.3s ease-in 0s 1 both;
}
@keyframes rtl2_0_kf {
  0% {
    left: 84px; 
  }
  90% {
    left: 0px;
  }
  93% {
    left: 0.0px;
  }
  100% {
    left: 0px;
  }
}


.btt2_0 {
  animation: btt2_0_kf 0.3s ease-in 0s 1 both;
}
@keyframes btt2_0_kf {
  0% {
    top: 84px; 
  }
  90% {
    top: 0px;
  }
  93% {
    top: 0.0px;
  }
  100% {
    top: 0px;
  }
}


.ltr0_3 {
  animation: ltr0_3_kf 0.5s ease-in 0s 1 both;
}
@keyframes ltr0_3_kf {
  0% {
    left: 0px; 
  }
  90% {
    left: 126px;
  }
  93% {
    left: 117.2px;
  }
  100% {
    left: 126px;
  }
}


.rtl3_0 {
  animation: rtl3_0_kf 0.5s ease-in 0s 1 both;
}
@keyframes rtl3_0_kf {
  0% {
    left: 126px; 
  }
  90% {
    left: 0px;
  }
  93% {
    left: 0.0px;
  }
  100% {
    left: 0px;
  }
}


.btt3_0 {
  animation: btt3_0_kf 0.5s ease-in 0s 1 both;
}
@keyframes btt3_0_kf {
  0% {
    top: 126px; 
  }
  90% {
    top: 0px;
  }
  93% {
    top: 0.0px;
  }
  100% {
    top: 0px;
  }
}


.ltr0_4 {
  animation: ltr0_4_kf 0.7s ease-in 0s 1 both;
}
@keyframes ltr0_4_kf {
  0% {
    left: 0px; 
  }
  90% {
    left: 168px;
  }
  93% {
    left: 156.2px;
  }
  100% {
    left: 168px;
  }
}


.rtl4_0 {
  animation: rtl4_0_kf 0.7s ease-in 0s 1 both;
}
@keyframes rtl4_0_kf {
  0% {
    left: 168px; 
  }
  90% {
    left: 0px;
  }
  93% {
    left: 0.0px;
  }
  100% {
    left: 0px;
  }
}


.btt4_0 {
  animation: btt4_0_kf 0.7s ease-in 0s 1 both;
}
@keyframes btt4_0_kf {
  0% {
    top: 168px; 
  }
  90% {
    top: 0px;
  }
  93% {
    top: 0.0px;
  }
  100% {
    top: 0px;
  }
}


.ltr0_5 {
  animation: ltr0_5_kf 0.8s ease-in 0s 1 both;
}
@keyframes ltr0_5_kf {
  0% {
    left: 0px; 
  }
  90% {
    left: 210px;
  }
  93% {
    left: 195.3px;
  }
  100% {
    left: 210px;
  }
}


.rtl5_0 {
  animation: rtl5_0_kf 0.8s ease-in 0s 1 both;
}
@keyframes rtl5_0_kf {
  0% {
    left: 210px; 
  }
  90% {
    left: 0px;
  }
  93% {
    left: 0.0px;
  }
  100% {
    left: 0px;
  }
}


.btt5_0 {
  animation: btt5_0_kf 0.8s ease-in 0s 1 both;
}
@keyframes btt5_0_kf {
  0% {
    top: 210px; 
  }
  90% {
    top: 0px;
  }
  93% {
    top: 0.0px;
  }
  100% {
    top: 0px;
  }
}


.ltr0_6 {
  animation: ltr0_6_kf 1.0s ease-in 0s 1 both;
}
@keyframes ltr0_6_kf {
  0% {
    left: 0px; 
  }
  90% {
    left: 252px;
  }
  93% {
    left: 234.4px;
  }
  100% {
    left: 252px;
  }
}


.rtl6_0 {
  animation: rtl6_0_kf 1.0s ease-in 0s 1 both;
}
@keyframes rtl6_0_kf {
  0% {
    left: 252px; 
  }
  90% {
    left: 0px;
  }
  93% {
    left: 0.0px;
  }
  100% {
    left: 0px;
  }
}


.btt6_0 {
  animation: btt6_0_kf 1.0s ease-in 0s 1 both;
}
@keyframes btt6_0_kf {
  0% {
    top: 252px; 
  }
  90% {
    top: 0px;
  }
  93% {
    top: 0.0px;
  }
  100% {
    top: 0px;
  }
}


.htb0_1 {
  animation: htb0_1_kf 0.2s ease-in 0s 1 both;
}
@keyframes htb0_1_kf {
  0% {
    transform: translate(0px, 0px);
  }
  90% {
    transform: translate(0px, 42px);
  }
  93% {
    transform: translate(0px, 39.06px);
  }
  100% {
    transform: translate(0px, 42px);
  }
}


.ltr1_2 {
  animation: ltr1_2_kf 0.2s ease-in 0s 1 both;
}
@keyframes ltr1_2_kf {
  0% {
    left: 42px; 
  }
  90% {
    left: 84px;
  }
  93% {
    left: 78.1px;
  }
  100% {
    left: 84px;
  }
}


.rtl2_1 {
  animation: rtl2_1_kf 0.2s ease-in 0s 1 both;
}
@keyframes rtl2_1_kf {
  0% {
    left: 84px; 
  }
  90% {
    left: 42px;
  }
  93% {
    left: 39.1px;
  }
  100% {
    left: 42px;
  }
}


.btt2_1 {
  animation: btt2_1_kf 0.2s ease-in 0s 1 both;
}
@keyframes btt2_1_kf {
  0% {
    top: 84px; 
  }
  90% {
    top: 42px;
  }
  93% {
    top: 39.1px;
  }
  100% {
    top: 42px;
  }
}


.ltr1_3 {
  animation: ltr1_3_kf 0.3s ease-in 0s 1 both;
}
@keyframes ltr1_3_kf {
  0% {
    left: 42px; 
  }
  90% {
    left: 126px;
  }
  93% {
    left: 117.2px;
  }
  100% {
    left: 126px;
  }
}


.rtl3_1 {
  animation: rtl3_1_kf 0.3s ease-in 0s 1 both;
}
@keyframes rtl3_1_kf {
  0% {
    left: 126px; 
  }
  90% {
    left: 42px;
  }
  93% {
    left: 39.1px;
  }
  100% {
    left: 42px;
  }
}


.btt3_1 {
  animation: btt3_1_kf 0.3s ease-in 0s 1 both;
}
@keyframes btt3_1_kf {
  0% {
    top: 126px; 
  }
  90% {
    top: 42px;
  }
  93% {
    top: 39.1px;
  }
  100% {
    top: 42px;
  }
}


.ltr1_4 {
  animation: ltr1_4_kf 0.5s ease-in 0s 1 both;
}
@keyframes ltr1_4_kf {
  0% {
    left: 42px; 
  }
  90% {
    left: 168px;
  }
  93% {
    left: 156.2px;
  }
  100% {
    left: 168px;
  }
}


.rtl4_1 {
  animation: rtl4_1_kf 0.5s ease-in 0s 1 both;
}
@keyframes rtl4_1_kf {
  0% {
    left: 168px; 
  }
  90% {
    left: 42px;
  }
  93% {
    left: 39.1px;
  }
  100% {
    left: 42px;
  }
}


.btt4_1 {
  animation: btt4_1_kf 0.5s ease-in 0s 1 both;
}
@keyframes btt4_1_kf {
  0% {
    top: 168px; 
  }
  90% {
    top: 42px;
  }
  93% {
    top: 39.1px;
  }
  100% {
    top: 42px;
  }
}


.ltr1_5 {
  animation: ltr1_5_kf 0.7s ease-in 0s 1 both;
}
@keyframes ltr1_5_kf {
  0% {
    left: 42px; 
  }
  90% {
    left: 210px;
  }
  93% {
    left: 195.3px;
  }
  100% {
    left: 210px;
  }
}


.rtl5_1 {
  animation: rtl5_1_kf 0.7s ease-in 0s 1 both;
}
@keyframes rtl5_1_kf {
  0% {
    left: 210px; 
  }
  90% {
    left: 42px;
  }
  93% {
    left: 39.1px;
  }
  100% {
    left: 42px;
  }
}


.btt5_1 {
  animation: btt5_1_kf 0.7s ease-in 0s 1 both;
}
@keyframes btt5_1_kf {
  0% {
    top: 210px; 
  }
  90% {
    top: 42px;
  }
  93% {
    top: 39.1px;
  }
  100% {
    top: 42px;
  }
}


.ltr1_6 {
  animation: ltr1_6_kf 0.8s ease-in 0s 1 both;
}
@keyframes ltr1_6_kf {
  0% {
    left: 42px; 
  }
  90% {
    left: 252px;
  }
  93% {
    left: 234.4px;
  }
  100% {
    left: 252px;
  }
}


.rtl6_1 {
  animation: rtl6_1_kf 0.8s ease-in 0s 1 both;
}
@keyframes rtl6_1_kf {
  0% {
    left: 252px; 
  }
  90% {
    left: 42px;
  }
  93% {
    left: 39.1px;
  }
  100% {
    left: 42px;
  }
}


.btt6_1 {
  animation: btt6_1_kf 0.8s ease-in 0s 1 both;
}
@keyframes btt6_1_kf {
  0% {
    top: 252px; 
  }
  90% {
    top: 42px;
  }
  93% {
    top: 39.1px;
  }
  100% {
    top: 42px;
  }
}


.htb0_2 {
  animation: htb0_2_kf 0.3s ease-in 0s 1 both;
}
@keyframes htb0_2_kf {
  0% {
    transform: translate(0px, 0px);
  }
  90% {
    transform: translate(0px, 84px);
  }
  93% {
    transform: translate(0px, 78.12px);
  }
  100% {
    transform: translate(0px, 84px);
  }
}


.ltr2_3 {
  animation: ltr2_3_kf 0.2s ease-in 0s 1 both;
}
@keyframes ltr2_3_kf {
  0% {
    left: 84px; 
  }
  90% {
    left: 126px;
  }
  93% {
    left: 117.2px;
  }
  100% {
    left: 126px;
  }
}


.rtl3_2 {
  animation: rtl3_2_kf 0.2s ease-in 0s 1 both;
}
@keyframes rtl3_2_kf {
  0% {
    left: 126px; 
  }
  90% {
    left: 84px;
  }
  93% {
    left: 78.1px;
  }
  100% {
    left: 84px;
  }
}


.btt3_2 {
  animation: btt3_2_kf 0.2s ease-in 0s 1 both;
}
@keyframes btt3_2_kf {
  0% {
    top: 126px; 
  }
  90% {
    top: 84px;
  }
  93% {
    top: 78.1px;
  }
  100% {
    top: 84px;
  }
}


.ltr2_4 {
  animation: ltr2_4_kf 0.3s ease-in 0s 1 both;
}
@keyframes ltr2_4_kf {
  0% {
    left: 84px; 
  }
  90% {
    left: 168px;
  }
  93% {
    left: 156.2px;
  }
  100% {
    left: 168px;
  }
}


.rtl4_2 {
  animation: rtl4_2_kf 0.3s ease-in 0s 1 both;
}
@keyframes rtl4_2_kf {
  0% {
    left: 168px; 
  }
  90% {
    left: 84px;
  }
  93% {
    left: 78.1px;
  }
  100% {
    left: 84px;
  }
}


.btt4_2 {
  animation: btt4_2_kf 0.3s ease-in 0s 1 both;
}
@keyframes btt4_2_kf {
  0% {
    top: 168px; 
  }
  90% {
    top: 84px;
  }
  93% {
    top: 78.1px;
  }
  100% {
    top: 84px;
  }
}


.ltr2_5 {
  animation: ltr2_5_kf 0.5s ease-in 0s 1 both;
}
@keyframes ltr2_5_kf {
  0% {
    left: 84px; 
  }
  90% {
    left: 210px;
  }
  93% {
    left: 195.3px;
  }
  100% {
    left: 210px;
  }
}


.rtl5_2 {
  animation: rtl5_2_kf 0.5s ease-in 0s 1 both;
}
@keyframes rtl5_2_kf {
  0% {
    left: 210px; 
  }
  90% {
    left: 84px;
  }
  93% {
    left: 78.1px;
  }
  100% {
    left: 84px;
  }
}


.btt5_2 {
  animation: btt5_2_kf 0.5s ease-in 0s 1 both;
}
@keyframes btt5_2_kf {
  0% {
    top: 210px; 
  }
  90% {
    top: 84px;
  }
  93% {
    top: 78.1px;
  }
  100% {
    top: 84px;
  }
}


.ltr2_6 {
  animation: ltr2_6_kf 0.7s ease-in 0s 1 both;
}
@keyframes ltr2_6_kf {
  0% {
    left: 84px; 
  }
  90% {
    left: 252px;
  }
  93% {
    left: 234.4px;
  }
  100% {
    left: 252px;
  }
}


.rtl6_2 {
  animation: rtl6_2_kf 0.7s ease-in 0s 1 both;
}
@keyframes rtl6_2_kf {
  0% {
    left: 252px; 
  }
  90% {
    left: 84px;
  }
  93% {
    left: 78.1px;
  }
  100% {
    left: 84px;
  }
}


.btt6_2 {
  animation: btt6_2_kf 0.7s ease-in 0s 1 both;
}
@keyframes btt6_2_kf {
  0% {
    top: 252px; 
  }
  90% {
    top: 84px;
  }
  93% {
    top: 78.1px;
  }
  100% {
    top: 84px;
  }
}


.htb0_3 {
  animation: htb0_3_kf 0.5s ease-in 0s 1 both;
}
@keyframes htb0_3_kf {
  0% {
    transform: translate(0px, 0px);
  }
  90% {
    transform: translate(0px, 126px);
  }
  93% {
    transform: translate(0px, 117.18px);
  }
  100% {
    transform: translate(0px, 126px);
  }
}


.ltr3_4 {
  animation: ltr3_4_kf 0.2s ease-in 0s 1 both;
}
@keyframes ltr3_4_kf {
  0% {
    left: 126px; 
  }
  90% {
    left: 168px;
  }
  93% {
    left: 156.2px;
  }
  100% {
    left: 168px;
  }
}


.rtl4_3 {
  animation: rtl4_3_kf 0.2s ease-in 0s 1 both;
}
@keyframes rtl4_3_kf {
  0% {
    left: 168px; 
  }
  90% {
    left: 126px;
  }
  93% {
    left: 117.2px;
  }
  100% {
    left: 126px;
  }
}


.btt4_3 {
  animation: btt4_3_kf 0.2s ease-in 0s 1 both;
}
@keyframes btt4_3_kf {
  0% {
    top: 168px; 
  }
  90% {
    top: 126px;
  }
  93% {
    top: 117.2px;
  }
  100% {
    top: 126px;
  }
}


.ltr3_5 {
  animation: ltr3_5_kf 0.3s ease-in 0s 1 both;
}
@keyframes ltr3_5_kf {
  0% {
    left: 126px; 
  }
  90% {
    left: 210px;
  }
  93% {
    left: 195.3px;
  }
  100% {
    left: 210px;
  }
}


.rtl5_3 {
  animation: rtl5_3_kf 0.3s ease-in 0s 1 both;
}
@keyframes rtl5_3_kf {
  0% {
    left: 210px; 
  }
  90% {
    left: 126px;
  }
  93% {
    left: 117.2px;
  }
  100% {
    left: 126px;
  }
}


.btt5_3 {
  animation: btt5_3_kf 0.3s ease-in 0s 1 both;
}
@keyframes btt5_3_kf {
  0% {
    top: 210px; 
  }
  90% {
    top: 126px;
  }
  93% {
    top: 117.2px;
  }
  100% {
    top: 126px;
  }
}


.ltr3_6 {
  animation: ltr3_6_kf 0.5s ease-in 0s 1 both;
}
@keyframes ltr3_6_kf {
  0% {
    left: 126px; 
  }
  90% {
    left: 252px;
  }
  93% {
    left: 234.4px;
  }
  100% {
    left: 252px;
  }
}


.rtl6_3 {
  animation: rtl6_3_kf 0.5s ease-in 0s 1 both;
}
@keyframes rtl6_3_kf {
  0% {
    left: 252px; 
  }
  90% {
    left: 126px;
  }
  93% {
    left: 117.2px;
  }
  100% {
    left: 126px;
  }
}


.btt6_3 {
  animation: btt6_3_kf 0.5s ease-in 0s 1 both;
}
@keyframes btt6_3_kf {
  0% {
    top: 252px; 
  }
  90% {
    top: 126px;
  }
  93% {
    top: 117.2px;
  }
  100% {
    top: 126px;
  }
}


.htb0_4 {
  animation: htb0_4_kf 0.7s ease-in 0s 1 both;
}
@keyframes htb0_4_kf {
  0% {
    transform: translate(0px, 0px);
  }
  90% {
    transform: translate(0px, 168px);
  }
  93% {
    transform: translate(0px, 156.24px);
  }
  100% {
    transform: translate(0px, 168px);
  }
}


.ltr4_5 {
  animation: ltr4_5_kf 0.2s ease-in 0s 1 both;
}
@keyframes ltr4_5_kf {
  0% {
    left: 168px; 
  }
  90% {
    left: 210px;
  }
  93% {
    left: 195.3px;
  }
  100% {
    left: 210px;
  }
}


.rtl5_4 {
  animation: rtl5_4_kf 0.2s ease-in 0s 1 both;
}
@keyframes rtl5_4_kf {
  0% {
    left: 210px; 
  }
  90% {
    left: 168px;
  }
  93% {
    left: 156.2px;
  }
  100% {
    left: 168px;
  }
}


.btt5_4 {
  animation: btt5_4_kf 0.2s ease-in 0s 1 both;
}
@keyframes btt5_4_kf {
  0% {
    top: 210px; 
  }
  90% {
    top: 168px;
  }
  93% {
    top: 156.2px;
  }
  100% {
    top: 168px;
  }
}


.ltr4_6 {
  animation: ltr4_6_kf 0.3s ease-in 0s 1 both;
}
@keyframes ltr4_6_kf {
  0% {
    left: 168px; 
  }
  90% {
    left: 252px;
  }
  93% {
    left: 234.4px;
  }
  100% {
    left: 252px;
  }
}


.rtl6_4 {
  animation: rtl6_4_kf 0.3s ease-in 0s 1 both;
}
@keyframes rtl6_4_kf {
  0% {
    left: 252px; 
  }
  90% {
    left: 168px;
  }
  93% {
    left: 156.2px;
  }
  100% {
    left: 168px;
  }
}


.btt6_4 {
  animation: btt6_4_kf 0.3s ease-in 0s 1 both;
}
@keyframes btt6_4_kf {
  0% {
    top: 252px; 
  }
  90% {
    top: 168px;
  }
  93% {
    top: 156.2px;
  }
  100% {
    top: 168px;
  }
}


.htb0_5 {
  animation: htb0_5_kf 0.8s ease-in 0s 1 both;
}
@keyframes htb0_5_kf {
  0% {
    transform: translate(0px, 0px);
  }
  90% {
    transform: translate(0px, 210px);
  }
  93% {
    transform: translate(0px, 195.3px);
  }
  100% {
    transform: translate(0px, 210px);
  }
}


.ltr5_6 {
  animation: ltr5_6_kf 0.2s ease-in 0s 1 both;
}
@keyframes ltr5_6_kf {
  0% {
    left: 210px; 
  }
  90% {
    left: 252px;
  }
  93% {
    left: 234.4px;
  }
  100% {
    left: 252px;
  }
}


.rtl6_5 {
  animation: rtl6_5_kf 0.2s ease-in 0s 1 both;
}
@keyframes rtl6_5_kf {
  0% {
    left: 252px; 
  }
  90% {
    left: 210px;
  }
  93% {
    left: 195.3px;
  }
  100% {
    left: 210px;
  }
}


.btt6_5 {
  animation: btt6_5_kf 0.2s ease-in 0s 1 both;
}
@keyframes btt6_5_kf {
  0% {
    top: 252px; 
  }
  90% {
    top: 210px;
  }
  93% {
    top: 195.3px;
  }
  100% {
    top: 210px;
  }
}


.htb0_6 {
  animation: htb0_6_kf 1.0s ease-in 0s 1 both;
}
@keyframes htb0_6_kf {
  0% {
    transform: translate(0px, 0px);
  }
  90% {
    transform: translate(0px, 252px);
  }
  93% {
    transform: translate(0px, 234.36px);
  }
  100% {
    transform: translate(0px, 252px);
  }
}


.htb0_7 {
  animation: htb0_7_kf 1.2s ease-in 0s 1 both;
}
@keyframes htb0_7_kf {
  0% {
    transform: translate(0px, 0px);
  }
  90% {
    transform: translate(0px, 294px);
  }
  93% {
    transform: translate(0px, 273.42px);
  }
  100% {
    transform: translate(0px, 294px);
  }
}

