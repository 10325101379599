.btn-rotate {
  width: 70px;
  height: 50px;
  margin: 0.1em;
  background: rgb(34, 197, 94);
}
.btn-rotate:hover {
  background: #888888;
}

.controls-container-main {
  border: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.controls-container-sub {
  border: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.active {}
.inactive {
  opacity: 50%;
  filter: grayscale(1);
}
