.Start {
    width: 400px;

    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: lightgrey;
}
.start-footer {
    margin-bottom: 2em;
    font-size: larger;
}

.pivot-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    color: lightgrey;
}
.pivot-logo > * {
    margin-left: 0.5px;
    margin-right: 0.5px;
}

.up {
    animation: 2s ease-in-out 0s 1 alternate kf_up;
}
.down {
    animation: 2s ease-in-out 0s 1 alternate kf_down;
}
.left {
    animation: 2s linear 0s 1 alternate kf_left;
}
.right {
    animation: 2s linear 0s 1 alternate kf_right;
}
idle {}
@keyframes kf_up {
    0% { transform: translateY(0); }
    50% { transform: translateY(-30px); }
    100% { transform: translateY(0px); }
}
@keyframes kf_down {
    0% { transform: translateY(0); }
    50% { transform: translateY(30px); }
    100% { transform: translateY(0); }
}
@keyframes kf_left {
    0% { transform: translate(0); }
    50% { transform: translateX(-30px); }
    100% { transform: translateX(0); }
}
@keyframes kf_right {
    0% { transform: translate(0); }
    50% { transform: translateX(30px); }
    100% { transform: translateX(0); }
}

.tilt {
    transform: rotate(15deg);
}

.welcome {
    font-size: larger;
    margin-top: 1em;
    margin-bottom: 2.5em;
}

.Start .choice {
    margin-top: 2em;
}

.btn-choice {
    width: 300px;
}

.btn-bot {
    color: lightgrey;
    background-color: rgb(36, 78, 213);
    border-color: rgb(36, 78, 213);
}
.btn-bot:hover {
    color: lightgrey;
    background-color: rgb(59, 130, 246);
    border-color: rgb(36, 78, 213);
}

.btn-challenge {
    color: rgb(239, 68, 68);
    background-color: rgba(0, 0, 0, 0);
    border-color: rgb(239, 68, 68);
}

.btn-challenge:hover {
    color: lightgrey;
    background-color: rgb(239, 68, 68);
    border-color: rgb(183, 41, 41);
}

.info-offline {
    text-align: center;
    color: grey;
    font-size: smaller;
    font-style: italic;
}