.info-nav {
    color: lightgrey;
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;

    width: 300px;
    margin-top: 1em;

    color: grey;
    font-size: larger;
}

.info-nav-left {
    flex: 1;
}

.info-nav-right {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.btn-menu {
    color: grey;
    text-decoration: none;
    font-size: larger;
}
.btn-menu:hover {
    color: rgb(59, 130, 246);
}

a {
    color: grey;
    text-decoration: none;
}

a:hover {
    color: rgb(59, 130, 246);
}