body {
  min-height: 100vh;
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  background: #232323;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App {
  animation: fadeIn 1s;

  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
