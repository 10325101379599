* {
    box-sizing: border-box;
}

.board-header {
    width: 294px;
    height: 43px;
    border-collapse: collapse;
    border-spacing: 0;
    position: relative;
}

.board-header-cell {
    width: 42px;
    height: 42px;
    padding: 2px;
    position: absolute;
    visibility: hidden;
    z-index: 200;
}

.active {}
.inactive {
    opacity: 50%;
    filter: grayscale(1);
}

.board-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.board {
    height: 295px;
    width: 295px;
    position: relative;

    cursor: pointer;

    background-image:
            linear-gradient(
                    rgba(128, 128, 128, 0.5) 0.5px,
                    transparent 0.5px,
                    transparent calc(100% - 0.5px),
                    rgba(128, 128, 128, 0.5) calc(100% - 0.5px)
            ),
            linear-gradient(
                    90deg,
                    rgba(128, 128, 128, 0.5) 0.5px,
                    transparent 1px,
                    transparent calc(100% - 0.5px),
                    rgba(128, 128, 128, 0.5) calc(100% - 0.5px)
            );

    background-size: 42px 42px;
    border: 0.5px solid rgba(128, 128, 128, 1);

    z-index: 10;
}
.board-cell {
    position: absolute;
    width: 42px;
    height: 42px;
    padding: 2px;
    border: 0;
    text-align: center;
    background-color: transparent;
    z-index: 100;
}

.hover {
    background-color: rgba(128, 128, 128, 0.2);
}
.hide {
    visibility: hidden;
}
.show {
    visibility: visible;
}

.bounce {
    animation-name: kf_bounce;
    animation-duration: 0.5s;
    animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.7);
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
}

@keyframes kf_bounce {
    0% {
        top: -5px;
    }
    100% {
        top: 5px;
    }
}

.no_rotation {
    /* must have a time otherwise onAnimationEnd does not get triggered */
    animation-name: kf_no_rotation;
    animation-duration: 0.5s;
    animation-direction: normal;
    animation-fill-mode: forwards;
}

.rotate_left {
    animation-name: kf_rotate_left;
    animation-duration: 0.7s;
    animation-direction: normal;
    animation-fill-mode: forwards;
}

.rotate_right {
    animation-name: kf_rotate_right;
    animation-duration: 0.7s;
    animation-direction: normal;
    animation-fill-mode: forwards;
}

.turn_upside_down {
    animation-name: kf_turn_upside_down;
    animation-duration: 1s;
    animation-direction: normal;
    animation-fill-mode: forwards;
}

@keyframes kf_no_rotation {
    from{ transform: rotate(0deg) }
    to  { transform: rotate(0deg) }
}

@keyframes kf_rotate_left {
    from{ transform: rotate(0deg)   }
    to  { transform: rotate(-90deg) }
}

@keyframes kf_rotate_right {
    from{ transform: rotate(0deg)  }
    to  { transform: rotate(90deg) }
}

@keyframes kf_turn_upside_down {
    from{ transform: rotate(0deg)   }
    to  { transform: rotate(180deg) }
}