.logo-container {
    display: flex;
    flex-direction: row;
}
.logo-container > a:hover {
    color: rgb(59, 130, 246);
}
.logo-stone {
    width: 20px;
    height: 20px;

    margin-left: 1px;
    margin-right: 1px;

    border-radius: 10px;
    border: grey 1px solid;

    text-align: center;
    vertical-align: middle;
    line-height: 18px;

    font-size: small;
}
.logo-container:hover > * {
    border: rgb(59, 130, 246) 1px solid;
}
.filled {
    background: grey;
    color: lightgrey;
}
.logo-container:hover > .filled {
    background: rgb(59, 130, 246);
    color: lightgrey;
}
.tilt {
    transform: rotate(15deg);
}
.logo-stone-text {
    font-weight: bold;
}