
.container-gameinfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    height: 130px;
    width: 100%;

    color: lightgrey;

    margin-bottom: 1em;
}
.gameinfo-comment {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: grey;
    font-size: smaller;
    font-style: italic;

    width: 100%;

    margin-top: 1em;
}
.gameinfo-wininfo {
    margin-bottom: 1em;
}
.gameinfo-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
    width: 100%;
    font-size: larger;
}
.gameinfo-row .winner-text {
    color: lightgrey;
    font-size: larger;
    margin-left: 0.5em;
    margin-right: 0.5em;
}
.gameinfo-invite {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    padding: 0.3em;
    border-radius: 0.3em;
}
.text-invite {
    font-style: italic;
}
.name-edit {
    /*color: lightgrey;*/
}

.re-match {
    margin-left: 1em;
    margin-right: 1em;
    display: flex;
    gap: 10px;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
}

.gameinfo-player {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 130px;

    color: lightgrey;

    padding: 0.3em 0.3em 0.3em 0.5em;

    border-radius: 0.3em;
}
.player-inactive {
    border: dimgrey dashed 1px;
}
.gameinfo-player-border {
    padding-top: 7px;
}

.gameinfo-player-active {
    border-top: 3px rgb(34, 197, 94) solid;
    transition-timing-function: ease-in-out;
    animation: border-pulse 2s infinite ease-in-out;
}
@keyframes border-pulse {
    0% {
        border-color: rgb(34, 197, 94);
    }
    50% {
        border-color: rgb(22, 101, 52);
    }
    100% {
        border-color: rgb(34, 197, 94);
    }
}

.player1 {
    background: rgba(127, 29, 29, 0.5);
}
.player2 {
    background: rgba(30, 64, 175, 0.5);
}

.player-name {
    margin-left: 0.5em;
    margin-right: 0.5em;
}
.player-invite {
    margin-left: 0.5em;
    margin-right: 0.5em;
}
.player-vs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    color: lightgrey;

    margin-top: 7px;
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.container-controls {
    margin-top: 1.5em;
}
.element-restart {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: flex-end;
}
.btn-restart {
    background: lightgrey;
}
.btn-invite {
    color: lightgrey;
    height: 48px;
    font-size: larger;

    flex: 1;
}
.btn-undo {
    flex: 1;
    color: lightgrey;
    font-size: larger;
}
.icon-restart {
    height: 25px;
    width: 25px;
}

@keyframes rotation {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(359deg);
    }
}

.rotation {
    animation: rotation 2s infinite linear;
}