.Game {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bot-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: larger;
}
.bot-form-label {
    font-size: larger;
    margin-top: 0.5em;
}
