.stone {
    width: 38px;
    height: 38px;
    border-radius: 19px;

    text-align: center;
    vertical-align: middle;
    line-height: 38px;

    font-size: 1.5em;
}
.big {
    width: 50px;
    height: 50px;
    border-radius: 25px;

    text-align: center;
    vertical-align: middle;
    line-height: 50px;

    font-size: 2em;
}
.stone-text {
    text-shadow: -3px 3px 2px #2c0202;
    font-weight: bold;
}

.red {
    background-image: radial-gradient(rgb(239, 68, 68) 0%, rgb(183, 41, 41) 99%);
}
.blue {
    background-image: radial-gradient(rgb(59, 130, 246) 0%, rgb(36, 78, 213) 99%);
}